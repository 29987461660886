// Copyright 2020. Plesk International GmbH. All rights reserved.

import React, { PureComponent } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';

const loadImage = src => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => {
        resolve(src);
    });
    image.addEventListener('error', reject);
    image.src = src;
});

const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

class Screenshot extends PureComponent {
    state = {
        src: null,
        status: 'loading',
    }

    componentDidMount() {
        // this is antipattern, I know
        this.mounted = true;
        Promise.all([loadImage(this.props.src), delay(1000)])
            .then(([src]) => {
                this.safeSetState({ status: 'success', src });
            })
            .catch(() => {
                this.safeSetState({ status: 'error' });
            });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    safeSetState(state) {
        if (this.mounted) {
            this.setState(state);
        }
    }

    render() {
        const { status, src } = this.state;
        const { onDelete, title } = this.props;
        return (
            <div
                className={classNames('message', 'screenshot', {
                    'is-light': status === 'success' || status === 'loading',
                    'is-danger': status === 'error',
                    'is-warning': status === 'fake',
                })}
            >
                <div className="message-header">
                    <span>{title}</span>
                    <a className="delete" onClick={() => onDelete()} />
                </div>
                <div className="message-body">
                    {status === 'success' && <img src={src} alt="" />}
                    {status === 'error' && 'Cannot make a screenshot.'}
                    {status === 'loading' && 'Loading...'}
                </div>
            </div>
        );
    }
}

Screenshot.propTypes = {
    title: string.isRequired,
    src: string.isRequired,
    onDelete: func.isRequired,
};

export default Screenshot;
