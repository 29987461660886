// Copyright 2020. Plesk International GmbH. All rights reserved.

import React, { Component } from 'react';
import { func } from 'prop-types';

class Form extends Component {
    state = {
        values: {
            url: '',
            width: '',
            height: '',
            type: 'png',
            scale: '',
            delay: '',
            cache: '',
            selector: '',
            fullPage: '0',
        },
        displaySettings: false,
    };

    handleSubmit = e => {
        e.preventDefault();
        const data = {};
        Object.keys(this.state.values).forEach(k => {
            if (this.state.values[k]) {
                data[k] = this.state.values[k];
            }
        });
        this.props.onSubmit(data);
        this.setState(prevState => ({
            values: {
                ...prevState.values,
                url: '',
            },
            displaySettings: false,
        }));
    };

    handleChange = e => {
        const k = e.target.name;
        const v = e.target.value;
        this.setState(prevState => ({
            values: {
                ...prevState.values,
                [k]: v,
            },
        }));
    };

    handleSettingsClick = () => {
        this.setState(prevState => ({
            displaySettings: !prevState.displaySettings,
        }));
    };

    render() {
        const {
            displaySettings,
            values: { url, width, height, type, scale, delay, cache, selector, fullPage },
        } = this.state;
        return (
            <form action="/" onSubmit={this.handleSubmit}>
                <div className="field has-addons">
                    <div className="control is-expanded">
                        <input
                            className="input"
                            type="text"
                            name="url"
                            placeholder="Enter a url to take a screenshot"
                            value={url}
                            onChange={this.handleChange}
                            autoFocus
                        />
                    </div>
                    <div className="control">
                        <a
                            className="button"
                            onClick={this.handleSettingsClick}
                            data-type="settings"
                        >
                            {'Settings'}
                        </a>
                    </div>
                    <div className="control">
                        <button
                            type="submit"
                            className="button is-info"
                            disabled={url === ''}
                        >
                            {'Make a shot'}
                        </button>
                    </div>
                </div>
                {displaySettings && (
                    <div className="columns">
                        <div className="column is-three-quarters" />
                        <div className="column">
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Width'}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-narrow">
                                        <input
                                            className="input"
                                            name="width"
                                            placeholder="1600"
                                            value={width}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Height'}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-narrow">
                                        <input
                                            className="input"
                                            name="height"
                                            placeholder="1200"
                                            value={height}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Format'}</label>
                                </div>
                                <div className="field-body">
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="type"
                                            value="png"
                                            checked={type === 'png'}
                                            onChange={this.handleChange}
                                        />
                                        {' PNG'}
                                    </label>
                                    <label className="radio">
                                        <input
                                            type="radio"
                                            name="type"
                                            value="jpeg"
                                            checked={type === 'jpeg'}
                                            onChange={this.handleChange}
                                        />
                                        {' JPEG'}
                                    </label>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Scale'}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-narrow">
                                        <input
                                            className="input"
                                            name="scale"
                                            placeholder="1"
                                            value={scale}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Delay'}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-narrow">
                                        <input
                                            className="input"
                                            name="delay"
                                            placeholder="0"
                                            value={delay}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Cache'}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-narrow">
                                        <input
                                            className="input"
                                            name="cache"
                                            placeholder="3600"
                                            value={cache}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Selector'}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-narrow">
                                        <input
                                            className="input"
                                            name="selector"
                                            placeholder=""
                                            value={selector}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{'Fullpage'}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-narrow">
                                        <input
                                            className="input"
                                            name="fullPage"
                                            placeholder=""
                                            value={fullPage}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </form>
        );
    }
}

Form.propTypes = {
    onSubmit: func.isRequired,
};

export default Form;
