// Copyright 2020. Plesk International GmbH. All rights reserved.

import React, { Component, Fragment } from 'react';
const { v4: uuidv4 } = require('uuid');
import { bool } from 'prop-types';
import { hot } from 'react-hot-loader';
import { stringify } from 'qs';
import classNames from 'classnames';
import Form from './Form';
import Screenshot from './Screenshot';
import './App.css';

class App extends Component {
    state = {
        screenshots: [],
    };

    handleSubmit = data => {
        const id = uuidv4();
        this.setState(prevState => ({
            screenshots: [
                {
                    id,
                    data,
                },
                ...prevState.screenshots,
            ],
        }));
    };

    handleDelete = id => {
        this.setState(prevState => ({
            screenshots: prevState.screenshots.filter(
                screenshot => screenshot.id !== id,
            ),
        }));
    };

    render() {
        const { fake } = this.props;
        const { screenshots } = this.state;
        const hasScreenshots = screenshots.length > 0;
        return (
            <Fragment>
                <div
                    className={classNames('space', {
                        'space--fake': fake,
                        'space--narrow': hasScreenshots,
                    })}
                />
                <section className="section form">
                    <div className="container form">
                        <h1 className="title form__title">{'WebPros Screenshot Service'}</h1>
                        {fake || (
                            <div className="box">
                                <Form onSubmit={this.handleSubmit} />
                            </div>
                        )}
                    </div>
                    {hasScreenshots && (
                        <div className="container">
                            {screenshots.map(({ id, data }) => (
                                <Screenshot
                                    key={id}
                                    title={data.url}
                                    src={`/?${stringify(data)}`}
                                    onDelete={() => this.handleDelete(id)}
                                />
                            ))}
                        </div>
                    )}
                </section>
            </Fragment>
        );
    }
}

App.propTypes = {
    fake: bool,
};

App.defaultProps = {
    fake: false,
};

export default hot(module)(App);
